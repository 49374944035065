<template>
  <v-container fluid class="pa-0 register">
  <back-to-store :title="$t('shippingBilling.shippingInformationPreview')"></back-to-store>
    <v-container fluid class="pa-16">
           <v-row class="flex-column-reverse flex-lg-row">
          <v-col cols="12" lg="8">
         <v-form ref="form">
        <v-col cols="12">
          <v-row>
            <p class="p josefin-light">{{$t('shippingBilling.address')}}</p>
          </v-row>
          <v-row>
            <v-text-field outlined v-model="address.address1[0]"></v-text-field>
          </v-row>
        </v-col>
        <v-row class="px-3 country_state">
          <v-col cols="12" lg="6" class="pr-lg-10">
            <v-row>
              <p class="p josefin-light">{{$t('shippingBilling.province')}}</p>
            </v-row>
            <v-row>
              <v-select
                :items="items"
                label="Elegir"
                v-model="address.state"
                dense
                outlined
              ></v-select>
            </v-row>
          </v-col>
          <v-col cols="12" lg="6" class="pl-lg-10">
            <v-row>
              <p class="p josefin-light">{{$t('shippingBilling.municipality')}}</p>
            </v-row>
            <v-row>
              <v-text-field outlined v-model="address.city"></v-text-field>
            </v-row>
          </v-col>
          <v-col cols="12" lg="6" class="pr-lg-10">
            <v-row>
              <p class="p josefin-light">{{$t('shippingBilling.postCode')}}</p>
            </v-row>
            <v-row>
              <v-text-field outlined v-model="address.postcode"
              :rules="validationRules.postCodeRules" ></v-text-field>
            </v-row>
          </v-col>
          <v-col cols="12" lg="6" class="pl-lg-10">
            <v-row>
              <p class="p josefin-light">{{$t('shippingBilling.phone')}}</p>
            </v-row>
            <v-row>
              <v-text-field outlined v-model="address.phone"
              :rules="validationRules.phoneRules"></v-text-field>
            </v-row>
          </v-col>
        </v-row>
        <v-row class="py-5">
          <v-col cols="12" class="justify-center d-flex justify-lg-start">
            <v-btn class="btn_second px-10" type="button" @click="createorUpdateAddress()">
             {{$t('shippingBilling.btnSave')}}
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
          </v-col>
          <v-col cols="1" class="d-none d-lg-flex">
            <v-img src="@/assets/Linea.png" contain class="line-account"></v-img>
          </v-col>
          <v-col cols="12" lg="3" class="pt-10 pb-10 pb-lg-0">
            <v-row>
              <p class="p josefin-light">{{getUser.name}}</p>
            </v-row>
            <v-row>
              <p class="copperplate">{{$t('profile.navigationList')}}</p>
            </v-row>
            <v-row>
              <hr class="hr-sub-menu " />
            </v-row>
            <v-row class="pt-5 hr-gold">
              <router-link to="/profile" class="go-to josefin-light p py-2">
                {{$t('profile.title')}}
                <v-img src="/icon/one-arrow.svg" width="10" class="ml-auto" />
              </router-link>
              <router-link to="/orders" class="go-to josefin-light p py-2">
                {{$t('profile.purchaseHistory')}}
                <v-img src="/icon/one-arrow.svg" width="10" class="ml-auto" />
              </router-link>
              <router-link to="/productos" class="go-to josefin-light p py-2 mb-5">
                {{$t('profile.logOut')}}
                <v-img src="/icon/one-arrow.svg" width="10" class="ml-auto" />
              </router-link>
            </v-row>
          </v-col>
        </v-row>

    </v-container>
  </v-container>
</template>
<script>
import Swal from "sweetalert2";
import { mapActions, mapGetters } from "vuex";
import backToStore from '@/components/home/back-to-store.vue';

export default {
  components: { backToStore },
  data() {
    return {
      items: [
        "Azúa",
        "Baoruco",
        "Barahona",
        "Dajabón",
        "Distrito Nacional",
        "Duarte",
        "Elías Pina",
        "El Seibo",
        "Espaillat",
        "Hato Mayor",
        "Independencia",
        "La Altagracia",
        "La Romana",
        "La Vega",
        "Maria Trinidad Sanchez",
        "Monseñor Nouel",
        "Monte Cristi",
        "Monte Plata",
        "Pedernales",
        "Peravia",
        "Puerto Plata",
        "Salcedo",
        "Samana",
        "Sánchez Ramírez",
        "San Cristobal",
        "San Jose de Ocoa",
        "San Juan",
        "San Pedro de Macorís",
        "Santiago",
        "Santiago Rodríguez",
        "Santo Domingo",
        "Valverde"
      ],
      address: {
        address1: [""],
        city: "Santo Domingo",
        country: "DO",
        phone: null,
        postcode: null,
        state: null,
        default_address: true
      }
    };
  },
  computed: {
    ...mapGetters("addresses", ["getAddressDefault"]),
    ...mapGetters("customer", ['getUser']),
    validationRules() {
      return {
        addressRules: [
          (v) => !!v || "Campo requerido",
        ],
        stateRules: [
          (v) => !!v || "Campo requerido",
        ],
        cityRules: [
          (v) => !!v || "Campo requerido",
        ],
        postCodeRules: [
          (v) => !!v || "Campo requerido",
          (v) => /^\d*$/.test(v) || "Solo números",
        ],
        phoneRules: [
          (v) => !!v || "Campo requerido",
          (v) => /^\d*$/.test(v) || "Solo números",
          (v) => (v.length >= 10) || "Mínimo 10 números",
          (v) => (v.length <= 14) || "Máximo 14 números"
        ],
      };
    }
  },
  methods: {
    ...mapActions("addresses", ["fetchCreateAddress"]),
    async createorUpdateAddress() {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          container: "contact-message-modal",
          confirmButton:
            "btn-success v-btn v-btn--is-elevated v-btn--has-bg theme--light v-size--default",
          title: "h6"
        },
        buttonsStyling: false
      });
      if (this.$refs.form.validate()) {
        try {
          if (this.getAddressDefault) {
            await this.fetchCreateAddress(this.address);
            swalWithBootstrapButtons.fire({
              title: this.$t('alerts.addressUpdate'),
              text: this.$t('alerts.addressUpdateMessage'),
              confirmButtonText: this.$t('alerts.btnConfirm'),
              icon: "success"
            });
          } else {
            await this.fetchCreateAddress(this.address);
            swalWithBootstrapButtons.fire({
              title: this.$t('alerts.addressCreate'),
              text: this.$t('alerts.addressCreateMessage'),
              confirmButtonText: this.$t('alerts.btnConfirm'),
              icon: "success"
            });
            this.$router.go();
          }
        } catch (error) {
          console.log(error);
          swalWithBootstrapButtons.fire({
            title: this.$t('alerts.errorMessage'),
            text: this.$t('alerts.tryLater'),
            confirmButtonText: this.$t('alerts.btnConfirm'),
            icon: "error"
          });
        }
      }
    }
  },
  watch: {
    getAddressDefault(val) {
      if (val) {
        this.address = { ...val, default_address: true };
      }
    }
  },
  async created() {
    if (this.getAddressDefault) {
      this.address = this.getAddressDefault;
      this.address.default_address = true;
    }
  }
};
</script>Í

<template>
  <v-card class="d-flex align-lg-end align-center ma-0 rounded-0 elevation-0 card-banner">
    <v-row no-gutters class="px-16 ">
      <v-col cols="12" lg="6">
        <h1 class="text-h5 text-lg-h4 copperplate black-grin text-center text-lg-left">
          {{ title }}</h1>
      </v-col>
      <v-col cols="12" lg="6" class="d-none d-lg-flex">
        <router-link to="/productos" class="back-to-store josefin-light h5 text-right">
          <p class="josefin-light h5">
            <span class="font-weight-light" style="letter-spacing: -4px; margin-right: 5px;">
              {{ arrowBack }}
            </span>
            {{ $t("text.returnToTheStore") }}
          </p>
        </router-link>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
export default {
  name: "backToStore",
  props: ["title"],
  data() {
    return {
      arrowBack: "<<"
    };
  }
};
</script>
<style lang="scss" scoped>
.card-banner {
  height: 200px;
  background: #e5c97f !important;
}
.v-toolbar__title {
  white-space: normal;
}
</style>
